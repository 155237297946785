import { LoanResponseEntity, LoanTypes } from '@nw-loans/loans.interfaces';
import {
    ChangeApplicationsPage,
    ChangeMarketingPreference,
    ChangeMarketingPreferenceError,
    ChangeMarketingPreferenceSuccess,
    GetMarketingPreference,
    GetMarketingPreferenceError,
    GetMarketingPreferenceSuccess,
    GetPaymentSchedule,
    GetPaymentScheduleSuccess,
    SendAcceptanceEmail,
    SendAcceptanceEmailSuccess,
} from './loans.actions';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { LoansService } from '@nw-loans/loans.service';
import {
    DeleteLoanApplication,
    DeleteLoanSuccess,
    GeneralActionError,
    GetApplications,
    GetApplicationsError,
    GetApplicationsSuccess,
    GetMambuData,
    UploadBankStatement,
    UploadBankStatementSuccess,
} from '@nw-loans/loans.actions';
import { GetUserDetails } from '@nw-auth/authorization.actions';
import { HttpResponse } from '@angular/common/http';
import { FileSaverService } from '@nw-common/services/file-saver.service';
import { Injectable } from '@angular/core';

export const defaultLoansState = {
    loans: {
        applications: null,
        draft_loans: null,
        noLoans: true,
        errors: {
            applications: null,
            draft_loans: null,
        },
        currentPage: {
            applications: 1,
            draft_loans: 1,
        }
    },
    marketingPreference: {
        email_contact: null,
        phone_contact: null,
        post_contact: null,
    }
};

export interface LoansStateModel {
    loans: {
        applications: LoanResponseEntity;
        draft_loans: LoanResponseEntity;
        noLoans: boolean;
        errors: {
            applications: string;
            draft_loans: string;
        };
        currentPage: {
            applications: number;
            draft_loans: number;
        };
    };
    marketingPreference: {
        email_contact: boolean;
        phone_contact: boolean;
        post_contact: boolean;
    };
}

@State<LoansStateModel>({
    name: 'loans',
    defaults: { ...defaultLoansState }
})
@Injectable()
export class LoansState {
    perPage = 7;

    constructor(private loansService: LoansService, private fileSaverService: FileSaverService) {
    }

    @Selector()
    static loanApplications(type: LoanTypes) {
        return createSelector([LoansState], (state: { loans: LoansStateModel }) => {
            return state.loans.loans[type];
        });
    }

    @Selector()
    static applicationsError(type: LoanTypes) {
        return createSelector([LoansState], (state: { loans: LoansStateModel }) => {
            return state.loans.loans.errors[type];
        });
    }

    @Selector()
    static currentPage(type: LoanTypes) {
        return createSelector([LoansState], (state: { loans: LoansStateModel }) => {
            return state.loans.loans.currentPage[type];
        });
    }

    @Selector()
    static marketingPreference(state: LoansStateModel) {
        return state.marketingPreference;
    }

    @Selector()
    static noLoans(state: LoansStateModel) {
        return state.loans.noLoans;
    }

    @Action(GetApplications)
    getApplications(ctx: StateContext<LoansStateModel>, action: GetApplications) {
        const state: LoansStateModel = ctx.getState();
        if (action.searchParam != null) {
            state.loans.currentPage[action.type] = 1;
        }
        this.loansService.getLoans(action.type, state.loans.currentPage[action.type], this.perPage, action.searchParam).subscribe(
            {
                next: response => {
                    ctx.dispatch(new GetApplicationsSuccess(action.type, response));
                    if (action.type === 'active_loans') {
                        const ids = response.items.map(loan => loan.id);
                        if (ids.length) {
                            ctx.dispatch(new GetMambuData(ids));
                        }
                    }
                },
                error: error => {
                    ctx.dispatch(new GetApplicationsError(error.message, action.type));
                }
            }
        );
    }

    @Action(GetMambuData)
    getMambuData(ctx: StateContext<LoansStateModel>, action: GetMambuData) {
        this.loansService.getMambuData(action.payload).subscribe(
            response => {
                const state: LoansStateModel = ctx.getState();
                const loans = { ...state.loans.applications.items };
                for (const el of Object.keys(loans).map(item => loans[item])) {
                    if (response[el.id]) {
                        for (const field of Object.keys(response[el.id])) {
                            el[field] = response[el.id][field];
                        }
                    }
                }
            },
            _ => {
                ctx.dispatch(new GeneralActionError());
            }
        );
    }

    @Action(GetApplicationsSuccess)
    getActiveApplicationsSuccess(ctx: StateContext<LoansStateModel>, action: GetApplicationsSuccess) {
        const state: LoansStateModel = ctx.getState();
        const loans = { ...state.loans };


        loans.noLoans = action.payload.items.length == 0
        loans[action.type] = action.payload;

        ctx.patchState({
            loans: loans
        });
    }

    @Action(GetApplicationsError)
    getApplicationsError(ctx: StateContext<LoansStateModel>, action: GetApplicationsError) {
        const state: LoansStateModel = ctx.getState();
        const loans = { ...state.loans };

        loans[action.type] = null;
        loans.errors[action.type] = action.error;

        ctx.patchState({
            loans: loans
        });
    }

    @Action(DeleteLoanApplication)
    deleteLoanApplication(ctx: StateContext<LoansStateModel>, action: DeleteLoanApplication) {
        this.loansService.deleteLoan(action.id).subscribe(
            _ => ctx.dispatch(new DeleteLoanSuccess()),
            _ => ctx.dispatch(new GeneralActionError())
        );
    }

    @Action(DeleteLoanSuccess)
    deleteLoanSuccess(ctx: StateContext<LoansStateModel>) {
        ctx.dispatch(new GetApplications('applications'));
        ctx.dispatch(new GetApplications('draft_loans'));
        ctx.dispatch(new GetApplications('closed_loans'));
        ctx.dispatch(new GetApplications('active_loans'));
    }

    @Action(UploadBankStatement)
    uploadBankStatement(ctx: StateContext<LoansStateModel>, action: UploadBankStatement) {
        this.loansService.uploadStatement(action.file).subscribe(
            response => {
                ctx.dispatch(new UploadBankStatementSuccess());
                ctx.dispatch(new GetUserDetails());
            },
            _ => ctx.dispatch(new GeneralActionError())
        );
    }

    @Action(GetPaymentSchedule)
    getPaymentSchedule(ctx: StateContext<LoansStateModel>, action: GetPaymentSchedule) {
        this.loansService.getPaymentSchedule(action.loanId).subscribe(
            (response: HttpResponse<Blob>) => {
                const contentDisposition = response.headers.get('content-disposition');
                this.fileSaverService.saveAs(response.body, contentDisposition.match(/=(.*)/)[1]);
                ctx.dispatch(new GetPaymentScheduleSuccess());
            },
            _ => {
                ctx.dispatch(new GeneralActionError());
            }
        );
    }

    @Action(SendAcceptanceEmail)
    sendAcceptanceEmail(ctx: StateContext<LoansStateModel>, action: SendAcceptanceEmail) {
        this.loansService.sendAcceptanceEmail(action.loanId).subscribe(
            response => {
                ctx.dispatch(new GetApplications('applications'));
                ctx.dispatch(new SendAcceptanceEmailSuccess(response));
            },
            error => {
                ctx.dispatch(new GeneralActionError(error.error));
            }
        );
    }

    @Action(ChangeApplicationsPage)
    changeApplicationsPage(ctx: StateContext<LoansStateModel>, action: ChangeApplicationsPage) {
        const state: LoansStateModel = ctx.getState();
        const loans = { ...state.loans };

        loans.currentPage[action.type] = action.page;
        ctx.dispatch(new GetApplications(action.type));

        ctx.patchState({
            loans: loans
        });
    }

    @Action(GetMarketingPreference)
    getMarketingPreference(ctx: StateContext<LoansStateModel>) {
        return this.loansService.getMarketingPreference().subscribe({
            next: response => {
                ctx.dispatch(new GetMarketingPreferenceSuccess(response));
            },
            error: error => {
                ctx.dispatch(new GetMarketingPreferenceError());
            }
        });
    }

    @Action(GetMarketingPreferenceSuccess)
    getMarketingPreferenceSuccess(ctx: StateContext<LoansStateModel>, action: GetMarketingPreferenceSuccess) {
        ctx.patchState({
            marketingPreference: action.payload
        });
    }

    @Action(ChangeMarketingPreference)
    changeMarketingPreference(ctx: StateContext<LoansStateModel>, action: ChangeMarketingPreference) {
        this.loansService.changeMarketingPreference(action.payload).subscribe(
            response => {
                ctx.dispatch(new ChangeMarketingPreferenceSuccess());
            },
            error => {
                ctx.dispatch(new ChangeMarketingPreferenceError());
            }
        );
    }
}
