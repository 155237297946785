import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoanMambuData, LoanResponseEntity } from './loans.interfaces';
import { environment } from '@nw-root/environments/environment';

@Injectable()
export class LoansService {

    constructor(private http: HttpClient) {
    }

    _objectToQueryString(obj) {
        const query = Object.keys(obj)
            .filter(key => obj[key] !== null && obj[key] !== '')
            .map(key => `${ key }=${ encodeURIComponent(obj[key]) }`)
            .join('&');
        return query.length > 0 ? `?${ query }` : null;
    }

    getLoans(loan_type: string = 'applications', page: number, per_page: number, searchParam: string = null) {
        return this.http.get<LoanResponseEntity>(
            `${environment.apiBaseURL}/api/v1/loans/${loan_type}${ this._objectToQueryString({ page: page, size: per_page, s: searchParam }) }`
        );
    }

    deleteLoan(id: number) {
        return this.http.delete(`${environment.apiBaseURL}/api/v1/loan_applications/${ id }`);
    }

    getMambuData(ids: number[]) {
        return this.http.get<LoanMambuData>(`${environment.apiBaseURL}/api/v1/my_account/mambu/${ this._objectToQueryString({ loans: ids }) }`);
    }

    getPaymentSchedule(loanId: number) {
        return this.http.get<Blob>(
            `${environment.apiBaseURL}/api/v1/loan_applications/schedule/${ loanId }`, {
                observe: 'response',
                responseType: 'blob' as 'json',
            }
        );
    }

    uploadStatement(data) {
        return this.http.post(`${environment.apiBaseURL}/api/v1/broker_bank_statement`, data);
    }

    sendAcceptanceEmail(loanId: number) {
        return this.http.post<string[]>(`${environment.apiBaseURL}/api/v1/loan_applications/accept/${ loanId }`, {});
    }

    getMarketingPreference() {
        return this.http.get(`${environment.apiBaseURL}/api/v1/broker/marketing`);
    }

    changeMarketingPreference(payload: any) {
        return this.http.post(`${environment.apiBaseURL}/api/v1/broker/marketing`, payload);
    }
}
